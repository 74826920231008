import * as React from "react"
import { Link } from "gatsby"
import Footer from "./footer"
import Navbar from "./navbar"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="w-full" data-is-root-path={isRootPath}>
      <Navbar></Navbar>
      <div class="w-full md:w-4/5 2xl:w-3/5  mx-auto items-center">
        <main>{children}</main>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Layout
