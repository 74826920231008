import * as React from "react"
import Footer from "./footer"
import Navbar from "./navbar"

const BlogLayout = ({ location, title, children }) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath

    return (
        <div className="w-full bg-secondary" data-is-root-path={isRootPath}>
            <Navbar></Navbar>
            <div class="w-full mx-auto items-center">
                <main>{children}</main>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default BlogLayout
