import PropTypes from "prop-types"
import React, { useState } from "react"

function Navbar() {

    return (
        <nav class="bg-secondary  px-2 sm:px-4 py-2.5 ">
            <div class="container flex flex-wrap flex-col md:flex-row justify-between items-center mx-auto">
                <a href="/" class="flex items-center">
                    <img class='mr-3 h-10 sm:h-12' src="/logo.png" alt="logo"></img>
                    <span class="self-center text-black text-xl font-semibold whitespace-nowrap ">Suman Shree Neupane</span>
                </a>
                <div class="w-full md:block md:w-auto">
                    <ul class="flex flex-row justify-center items-center my-auto flex-wrap mx-auto pt-2 md:p-4  md:space-x-8  md:text-sm md:font-medium md:border-0 ">
                        <li class="h-full my-auto">
                            <a href="/" class="block text-lg py-2 pr-4 pl-3  rounded md:bg-transparent md:hover:text-blue-700 md:p-0 " aria-current="page">Home</a>
                        </li>
                        {/* <li class="h-full my-auto">
                            <a href="/blog" class="block text-lg py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Blog</a>
                        </li> */}
                        <li class="h-full my-auto">
                            <a href="/contact" class="block py-2 text-lg pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 ">Contact</a>
                        </li>
                        <a download={`Suman Shree Neupane CV`} href={`/CV_SumanShreeNeupane.pdf`} class="relative inline-flex items-center px-5 py-2.5 text-center mr-3 overflow-hidden text-white bg-blue-600 rounded group active:bg-blue-500 focus:outline-none focus:ring" >
                            <span class="absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4">
                                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                                </svg>
                            </span>

                            <span class="text-lg font-medium transition-all group-hover:mr-4">
                                Download CV
                            </span>
                        </a>

                    </ul>
                </div>
            </div>
        </nav>
    )
}

Navbar.propTypes = {
    siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
    siteTitle: `Suman Shree Neupane`,
}

export default Navbar
